<template>
  <div class="w-p-100 h-p-100 bg-f3">
    <div
      class=" scroll-y w-p-100"
      :class="{
        'h-calc-45': detail.is_qualified == 0,
        'h-p-100': detail.is_qualified == 1
      }"
    >
      <van-cell title="培训课程" :value="detail.types_1_name" />
      <van-cell title="培训时间" :value="detail.train_start_date" />
      <van-cell title="承包商名称" :value="detail.ct_contractor_info_name" />
      <van-cell title="员工名称" :value="detail.ct_operator_info_id_name" />
      <van-cell title="首次考试成绩" :value="detail.first_score" />
      <van-cell title="订正次数" :value="detail.correct_num" />
      <van-cell title="答题时长" :value="detail.solve_time" />
      <div class="lP-10 rP-10 tP-10 bP-10 Fbg bM-10">
        <div
          class="flex alignCenter h-35"
          v-for="(item, index) in answerForm"
          :key="index"
        >
          <div class="size-16">
            {{
              index == 1
                ? "判断题"
                : index == 2
                ? "多选题"
                : index == 3
                ? "单选题"
                : index == 4
                ? "简答题"
                : ""
            }}
          </div>
          <ul class="flex alignCenter">
            <li
              class="h-24 w-24 radius-24 flex size-14 alignCenter justifyCenter lM-3 rM-3 color-fff"
              :class="{
                'bg-67c23a': items.is_correct == 1,
                'bg-ff0000': items.is_correct == 0,
                'bg-D5D5D5': items.is_correct == -1
              }"
              v-for="(items, index) in item"
              :key="index"
              @click="checkAnswerKey(items.id)"
            >
              {{ index + 1 }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      class="size-18 w-p-100 flex alignCenter justifyCenter"
      v-if="
        detail.is_qualified == 0 &&
          dayjs(new Date()).format('YYYY-MM-DD') ==
            dayjs(new Date(detail.train_start_date)).format('YYYY-MM-DD')
      "
    >
      <div
        class="h-35 bg-0F60A7 flex alignCenter justifyCenter  w-p-100 color-fff padding-5"
        @click="router.push('/AnswerDetails/' + route.params.id + '/0/0')"
      >
        订正错题
      </div>
    </div>
    <div
      class="size-18 w-p-100 flex alignCenter justifyCenter"
      v-if="detail.is_qualified == 1"
    >
      <div
        class="h-35 bg-0F60A7 flex alignCenter justifyCenter  w-p-100 color-fff padding-5"
        @click="router.push('/AnswerDetails/' + route.params.id + '/0/1')"
      >
        查看试卷
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive } from "vue";
import http from "../../../api/http";
import { useRoute, useRouter } from "vue-router";
import dayjs from "dayjs";
export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    const detail = reactive({
      types_1: ""
    });
    const answerForm = reactive({});
    onMounted(() => {
      http
        .get({
          method: "query",
          queryId: 1070,
          ct_train_id: route.params.id
        })
        .then(res => {
          Object.keys(res).map(key => {
            detail[key] = res[key];
          });
          var obj = {};
          res.ct_question_test_dtl_form.forEach(element => {
            if (!obj[element.types]) {
              obj[element.types] = [];
              obj[element.types].push(element);
            } else {
              obj[element.types].push(element);
            }
          });
          Object.keys(obj).map(key => {
            answerForm[key] = obj[key];
          });
        });
    });
    return {
      router,
      route,
      detail,
      answerForm,
      dayjs
    };
  }
};
</script>
